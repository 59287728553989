import React, { useState } from 'react';
import axios from 'axios';
import PasswordProtect from './PasswordProtect';

const ApiRequest = () => {
  const [inputValue, setInputValue] = useState('');
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleAuthenticate = () => {
    setIsAuthenticated(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isAuthenticated) {
      setError('Требуется аутентификация');
      return;
    }
    setLoading(true);
    setError(null);
    try {
      const res = await axios.post('http://85.193.87.56:8000/classify', { name: inputValue });
      setResponse(res.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h1>Скрипка ассистент</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={inputValue}
          onChange={handleChange}
          placeholder="Введите запрос"
          required
        />
        <button type="submit">Отправить</button>
      </form>
      {!isAuthenticated && <PasswordProtect onAuthenticate={handleAuthenticate} />}
      {loading && <p>Загрузка...</p>}
      {error && <p style={{ color: 'red' }}>Ошибка: {error}</p>}
      {response && (
        <div>
          <h2>Ответ:</h2>
          <pre>{JSON.stringify(response, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default ApiRequest;
