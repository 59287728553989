import React from 'react';
import ApiRequest from './ApiRequest';
import './App.css';

function App() {
  const handleEmailClick = () => {
    const recipient = 'skipkatech@ya.ru';
    const subject = encodeURIComponent('Запрос на стоимость программного обеспечения');
    const body = encodeURIComponent('Здравствуйте,\n\nЯ хотел бы узнать стоимость программного обеспечения, учитывая следующие задачи:\n\n- Задача 1\n- Задача 2\n\nПожалуйста, свяжитесь со мной по телефону (укажите ваш номер) или по электронной почте для дальнейшего обсуждения.\n\nС уважением,\n[Ваше имя]');
    window.location.href = `mailto:${recipient}?subject=${subject}&body=${body}`;
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="buttons">
          <button
            title="Инструкция по установке экземпляра программного обеспечения, предоставленного для проведения экспертной оценки"
            onClick={() => window.open('/1.pdf', '_blank')}
          >
            Инструкция
          </button>
          <button
            title="Инструкция по установке экземпляра программного обеспечения, предоставленного для проведения экспертной оценки"
            onClick={() => window.open('/2.pdf', '_blank')}
          >
            Характеристики
          </button>
          <button
            title="Инструкция по установке экземпляра программного обеспечения, предоставленного для проведения экспертной оценки"
            onClick={() => window.open('/3.pdf', '_blank')}
          >
            Правила
          </button>
          <button
            title="Содержание процессов, обеспечивающих поддержание жизненного цикла, в том числе устранение неисправностей и совершенствование, а также информацию о персонале, необходимом для обеспечения такой поддержки"
            onClick={() => window.open('/4.pdf', '_blank')}
          >
            Жизненный цикл
          </button>
          <button
            title="Запрос стоимости программного обеспечения при помощи функции, которая откроет окно почтовой программы с предзаполненными полями 'кому', 'темой' и 'текстом письма'"
            onClick={handleEmailClick}
          >
            Стоимость
          </button>
        </div>
        <ApiRequest />
      </header>
      <footer className="App-footer">
        <div>
          ООО Скрипка диджитал   Адрес компании: г. Саратов, ул. Некрасова 33/35
        </div>
        <div>
          <a href="/license-agreement.pdf" target="_blank" className="footer-link">Лицензионное соглашение</a> |
          <a href="/privacy-policy.pdf" target="_blank" className="footer-link">Согласие на обработку персональных данных</a>
        </div>
      </footer>
      <div className="info">
        <p>
          * При нажатии на "Стоимость" откроется окно вашей внутренней почтовой программы, куда необходимо вписать детали вашего проекта, чтобы точно определить стоимость программы для вас. Если у вас отсутствует почтовая программа, отправьте запрос по адресу электронной почты skipkatech@ya.ru. Если вы оставите свой контактный номер, мы свяжемся с вами в течение 24 часов.
        </p>
      </div>
    </div>
  );
}

export default App;
